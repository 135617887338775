<template>
  <ion-page>
    <HeaderOne v-bind:name="$t('vbb')" />
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{$t('vbb')}}</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-refresher slot="fixed" @ionRefresh="refresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>

      <div v-if="loading" class="loading">
        <ion-spinner name="crescent" color="primary"></ion-spinner>
        <h4>Lädt</h4>
      </div>

      <div v-if="error" class="loading">
        {{ error }}
      </div>

      <div class="vbb-content" v-if="loading === false && !departures.name">
        <img src="/assets/bug.svg" alt="Hier ist gerade nix los...">
        <ion-text class="text">{{$t('vbb_error')}}</ion-text>
      </div>

      <template v-if="departures">
        <div v-for="departure in departures.Departure" :key="departure.time">

          <ion-card>
            <ion-card-header>
              <ion-card-title>🚋 Linie {{ departure.name }}</ion-card-title>
              <ion-card-subtitle>
                {{ departure.time.slice(0, -3) }} – {{ new Date(departure.date).toLocaleDateString('de-DE') }}</ion-card-subtitle>
            </ion-card-header>

            <ion-card-content>
              <b>{{$t('direction')}}</b> {{ departure.direction }}<br/>
              <ion-badge color="primary">{{ getMoment(departure.date, departure.time) }} <!--{{ getTimeDiff(JSON.stringify(departure.date), JSON.stringify(departure.time)) }}--></ion-badge>
            </ion-card-content>
          </ion-card>
        </div>
      </template>

      <div v-if="departures.name" class="provider">
        <img src="/assets/vbb.png" width="35px" alt="VBB Logo"/>
        <div>
          <small>&nbsp; {{$t('vbb_disclaimer_1')}}</small><br />
          <small>&nbsp; {{$t('vbb_disclaimer_2')}}</small><br />
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonText, IonCard, IonCardTitle, IonCardSubtitle, IonCardHeader, IonCardContent, IonBadge, IonRefresher, IonRefresherContent } from '@ionic/vue';
import HeaderOne from '@/components/HeaderOne.vue'
import axios from "axios";
import { IAbfahrten } from "../../types/abfahrten";
import moment from 'moment';

export default  {
  name: 'VBB',
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, HeaderOne, IonText, IonCardTitle, IonCard, IonCardSubtitle, IonCardHeader, IonCardContent, IonBadge, IonRefresher, IonRefresherContent },
  data() {
    return {
      loading: false,
      error: null,
      departures: null as IAbfahrten,
    }
  },
  methods: {
    getDepartures: function () {
      this.error = this.departures = null
      this.loading = true
      axios.get('https://demo.hafas.de/openapi/vbb-proxy/departureBoard?id=900275122&direction=900275110&maxJourneys=7&lang=de&accessId=hammes-5b5f-4a7f-80c1-4a9e70c61f35', {
        headers: {
          'Accept': 'application/json'
        },
        timeout: 120000,
      })
              .then(response => {
                this.departures = response.data;
                this.loading = false;
              })
              .catch(function (err) {
                this.error = err.toString();
              })
    },
    refresh: function (event) {
      setTimeout(() => {
        this.getDepartures();
        event.target.complete();
      }, 1500);
    },
    getMoment: function (a, b) {
      moment.locale('de')
      return moment(a + 'T' + b).fromNow().toLocaleString();
    }
  },
  mounted() {
    this.getDepartures();
  }
}
</script>

<style scoped>
  .vbb-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 20px;
  }

  .vbb-content >  img {
    max-width: 300px;
    height: auto;
    margin: 30px;
  }

  .provider {
    display: flex;
    margin: 20px auto;
    justify-content: center;
    align-items: center;
  }
  .provider > img {
    width: 35px;
    margin-right: 20px;
  }
  .loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50%;
  }
</style>
