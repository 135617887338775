
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonText, IonCard, IonCardTitle, IonCardSubtitle, IonCardHeader, IonCardContent, IonBadge, IonRefresher, IonRefresherContent } from '@ionic/vue';
import HeaderOne from '@/components/HeaderOne.vue'
import axios from "axios";
import { IAbfahrten } from "../../types/abfahrten";
import moment from 'moment';

export default  {
  name: 'VBB',
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, HeaderOne, IonText, IonCardTitle, IonCard, IonCardSubtitle, IonCardHeader, IonCardContent, IonBadge, IonRefresher, IonRefresherContent },
  data() {
    return {
      loading: false,
      error: null,
      departures: null as IAbfahrten,
    }
  },
  methods: {
    getDepartures: function () {
      this.error = this.departures = null
      this.loading = true
      axios.get('https://demo.hafas.de/openapi/vbb-proxy/departureBoard?id=900275122&direction=900275110&maxJourneys=7&lang=de&accessId=hammes-5b5f-4a7f-80c1-4a9e70c61f35', {
        headers: {
          'Accept': 'application/json'
        },
        timeout: 120000,
      })
              .then(response => {
                this.departures = response.data;
                this.loading = false;
              })
              .catch(function (err) {
                this.error = err.toString();
              })
    },
    refresh: function (event) {
      setTimeout(() => {
        this.getDepartures();
        event.target.complete();
      }, 1500);
    },
    getMoment: function (a, b) {
      moment.locale('de')
      return moment(a + 'T' + b).fromNow().toLocaleString();
    }
  },
  mounted() {
    this.getDepartures();
  }
}
